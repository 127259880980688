import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

const SearchBoxBlock = styled.div`
  flex: 1;
  border: 2px solid #eee;
  position: relative;
  padding: 15px 15px 10px;

  .header {
    background-color: #fff;
    position: absolute;
    top: -10px;
    left: 0px;
    color: #999;
    padding: 0 10px;
  }

  .boxBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .form-group {
    width: 28%;
  }

  .btn {
    width: 100%;
  }
`;

interface ISearchForm {
  name?: string;
  ssn?: string;
  email?: string;
}

interface ISearchBoxProps extends ISearchForm {
  onSearch: (query: string) => void;
}

export const MiniSearchBox = ({ name, ssn, email, onSearch }: ISearchBoxProps) => {
  const {
    register,
    getValues,
    // setValue,
    handleSubmit,
    // setError,
  } = useForm<ISearchForm>({
    mode: "onChange",
  });

  const onSubmit = () => {
    const { name, ssn, email } = getValues();

    // if (!name && !ssn && !email) {
    //   alert("조건검색 중 적어도 하나의 조건을 입력해주세요.");
    //   return false;
    // }

    let query = "";

    query += name ? `&name=${name}` : "";
    query += ssn ? `&ssn=${ssn}` : "";
    query += email ? `&email=${email}` : "";

    onSearch(query);
  };

  return (
    <SearchBoxBlock>
      <div className="header">Search Box</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="boxBody">
          <div className="form-group">
            <label>이름</label>
            <input type="text" {...register("name")} placeholder="이름을 입력해주세요." defaultValue={name} />
          </div>
          <div className="form-group">
            <label>주민번호</label>
            <input type="text" {...register("ssn")} placeholder="주민번호를 '-' 제외하고 입력해주세요." defaultValue={ssn} />
          </div>
          <div className="form-group">
            <label>이메일</label>
            <input type="text" {...register("email")} placeholder="이메일을 입력해주세요." defaultValue={email} />
          </div>
          <div className="btn-group">
            <button className="btn">검색하기</button>
          </div>
        </div>
      </form>
    </SearchBoxBlock>
  );
};

MiniSearchBox.defaultProps = {
  name: "",
  ssn: "",
  email: "",
};
