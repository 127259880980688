import React from "react";
import styled from "styled-components";
import JsPagination from "react-js-pagination";

const StylePaginationBlock = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
  ul.pagination li:first-child {
    border-radius: 5px 0 0 5px;
  }
  ul.pagination li:last-child {
    border-radius: 0 5px 5px 0;
  }
  ul.pagination li a {
    text-decoration: none;
    color: #424242;
    font-size: 1rem;
  }
  ul.pagination li.active a {
    color: white;
  }
  ul.pagination li.active a:hover {
    color: white;
  }

  ul.pagination li.active {
    background-color: #424242;
  }
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: #424242;
  }

  .page-selection {
    width: 48px;
    height: 30px;
    color: #424242;
  }
`;

interface IPaginationProps {
  page: number;
  postsPerPage: number;
  total: number;
  paginate: (changePage: number) => void;
}

const Pagination = ({ page, postsPerPage, total, paginate }: IPaginationProps) => {
  return (
    <StylePaginationBlock>
      <JsPagination
        activePage={page}
        itemsCountPerPage={postsPerPage}
        totalItemsCount={total}
        pageRangeDisplayed={10}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={paginate}
      />
    </StylePaginationBlock>
  );
};

Pagination.defaultProps = {
  page: 1,
  postsPerPage: 10,
};

export default Pagination;
