import React, { useEffect, useState } from "react";
import queryString from "query-string";

export const Fail = ({ location }: any) => {
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const query = queryString.parse(location?.search);
        const { message, code }: any = query;

        setCode(code);
        setMessage(message);
      } catch (e) {
        console.log(e);
      }
    })();
  }, [location]);

  return (
    <>
      <h3>결재 실패</h3>
      <div>
        <br />
        <br />
        <p>{message}</p>
        <br />
        <span>에러코드: {code}</span>
      </div>
    </>
  );
};
