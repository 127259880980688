import React from "react";
import styled from "styled-components";

interface ISelectBoxProps {
  options: {
    name: string;
    value: string;
  }[];
  onSelcet: (value: string) => void;
  defaultValue: string;
}

export const Select = styled.select`
  margin: 0;
  min-width: 100px;
  display: block;
  width: 100%;
  padding: 8px 8px;
  font-size: inherit;
  line-height: inherit;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #666;

  background-color: transparent;
  &:focus {
    border-color: red;
  }

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

const SelectBoxWrapper = styled.div`
  display: flex;
`;

// Icon에 사용할 Icon SVG 컴포넌트 생성
const IconSVG = styled.svg`
  margin-left: -28px;
  align-self: center;
  width: 24px;
  height: 24px;
`;

export const SelectBox = ({ options, defaultValue, onSelcet }: ISelectBoxProps) => {
  const handleChange = (e: any) => {
    onSelcet(e.target.value);
  };

  return (
    <SelectBoxWrapper>
      <Select onChange={handleChange} defaultValue={defaultValue}>
        {options.map((option) => (
          <option key={option.value} value={option.value} defaultValue={defaultValue}>
            {option.name}
          </option>
        ))}
      </Select>
      <IconSVG width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10 14L16 6H4L10 14Z" fill="#1A1A1A" />
      </IconSVG>
    </SelectBoxWrapper>
  );
};
