import React from "react";
import { IndexRouter } from "../routers";
import AppProvider from "../context";

export const App = () => {
  return (
    <AppProvider>
      <IndexRouter />
    </AppProvider>
  );
};
