import React from "react";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background: #e9ecef;
  }
`;

const AuthWrapBlock = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AuthTemplateBlock = styled.div`
  width: 350px;
  height: 420px;

  position: relative; /* 추후 박스 하단에 추가 버튼을 위치시키기 위한 설정 */
  background: white;
  border-radius: 16px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);
`;

export const AuthLayout: React.FC = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <AuthWrapBlock>
        <AuthTemplateBlock>{children}</AuthTemplateBlock>
      </AuthWrapBlock>
    </>
  );
};
