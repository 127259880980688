import React, { useEffect } from "react";
import { useAuthDispatch } from "../../context/authContext";
import { useHistory } from "react-router-dom";

export const Logout = () => {
  const dispath = useAuthDispatch();
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("token");
    dispath({ type: "LOGOUT" });
    history.push(`/auth/login`);
    // eslint-disable-next-line
  }, []);

  return <></>;
};
