import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { loadTossPayments } from "@tosspayments/sdk";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { FormError } from "../../components/form-error";

const clientKey = `${process.env.REACT_APP_TOSS_CLIENT_KEY}`;
const API_ENDPONIT = `${process.env.REACT_APP_API_ENDPOINT}`;

interface IForm {
  name: string;
  jumin1: number;
  jumin2: number;
  email?: string;
  price1?: number;
  price2?: number;
  price3?: number;
  price4?: number;
  price5?: number;
  price: number;
  contents?: string;
}

export const Home = () => {
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IForm>({
    mode: "onBlur",
  });

  const [sending, setSending] = useState(false);

  const savePaymentsInfo = async (orderId: string) => {
    try {
      const { name, jumin1, jumin2, email, price1 = 0, price2 = 0, price3 = 0, price4 = 0, price5 = 0, contents } = getValues();

      return await axios.post(`${API_ENDPONIT}payment/create`, {
        name,
        jumin1,
        jumin2,
        email,
        price1: Number(price1),
        price2: Number(price2),
        price3: Number(price3),
        price4: Number(price4),
        price5: Number(price5),
        contents,
        orderId,
      });
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const onSubmit = async () => {
    if (!sending) {
      const { name, jumin1, jumin2, price, price1 = 0, price2 = 0, price3 = 0, price4 = 0, price5 = 0 } = getValues();
      const regex = / /gi;

      if (name.trim().match(regex)) {
        setError("name", {
          type: "menual",
          message: "성과 이름을 공백없이 붙여쓰세요.",
        });
        return false;
      }

      if (!ssnCheck(jumin1, jumin2)) {
        setError("jumin1", {
          type: "menual",
          message: "주민번호를 정확히 입력해주세요.",
        });
        return false;
      }

      if (!price) {
        setError("price", {
          type: "menual",
          message: "금액을 입력해주세요.",
        });
        return false;
      }

      const total = Number(price1) + Number(price2) + Number(price3) + Number(price4) + Number(price5);

      if (total !== Number(price)) {
        setError("price", {
          type: "menual",
          message: "금액을 정확히 입력해주세요.",
        });
        return false;
      }

      const orderId = uuidv4();

      const response = await savePaymentsInfo(orderId);

      if (!response) {
        alert("정상적인 접근에 실패 했습니다.");
        return false;
      }

      setSending(true);
      const tossPayments = await loadTossPayments(clientKey);

      tossPayments.requestPayment("카드", {
        amount: price,
        orderId,
        orderName: "예수중심교회 인터넷헌금",
        customerName: name,
        successUrl: window.location.origin + "/success",
        failUrl: window.location.origin + "/fail",
      });
    }
  };

  const handleChangePrice = (e: any) => {
    const { value } = e.target;

    if (value) {
      const { price1 = 0, price2 = 0, price3 = 0, price4 = 0, price5 = 0 } = getValues();
      setValue("price", Number(price1) + Number(price2) + Number(price3) + Number(price4) + Number(price5));
    }
  };

  const ssnCheck = (ssn1: number, ssn2: number) => {
    const ssn = `${ssn1}${ssn2}`;
    if (ssn.length !== 13) return false;

    let checkSum = 0;
    for (let i = 0; i < 12; i++) checkSum += (Number(ssn.substr(i, 1)) >> 0) * ((i % 8) + 2);

    // 검증
    const modCheckSum = checkSum % 11; // 검증값 합계의 11의 나머지수
    const ssnMatch = (11 - modCheckSum) % 10 === Number(ssn.substr(12, 1));

    return ssnMatch;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group required">
          <label>이름</label>
          <input type="text" {...register("name", { required: "이름을 입력해주세요." })} placeholder="성과 이름을 빈칸 없이 붙여 쓰세요" />
          {errors.name?.type === "required" && <FormError errorMessage={errors.name.message} />}
          {errors.name?.type === "menual" && <FormError errorMessage={errors.name.message} />}
        </div>
        <div className="flex">
          <div className="form-group required">
            <label>주민번호(연말정산용)</label>
            <input type="text" {...register("jumin1", { required: "주민번호를 입력해주세요." })} />
            {(errors.jumin1?.type === "required" || errors.jumin2?.type === "required") && (
              <FormError errorMessage={"주민번호를 입력해주세요."} />
            )}
            {errors.jumin1?.type === "menual" && <FormError errorMessage={errors.jumin1.message} />}
          </div>
          <div>-</div>
          <div className="form-group">
            <label>&nbsp;</label>
            <input type="text" {...register("jumin2", { required: "주민번호를 입력해주세요." })} />
          </div>
        </div>
        <div className="form-group">
          <label>이메일</label>
          <input
            type="text"
            // eslint-disable-next-line
            {...register("email", { pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ })}
            placeholder="선택사항입니다."
          />
          {errors.email?.type === "pattern" && <FormError errorMessage={"이메일을 정확히 입력해주세요."} />}
        </div>
        <div className="flex">
          <div className="form-group">
            <label>십일조</label>
            <input type="number" {...register("price1")} onBlur={handleChangePrice} />
          </div>
          <div className="form-group">
            <label>감사헌금</label>
            <input type="number" {...register("price2")} onBlur={handleChangePrice} />
          </div>
          <div className="form-group">
            <label>건축헌금</label>
            <input type="number" {...register("price3")} onBlur={handleChangePrice} />
          </div>
        </div>
        <div className="flex">
          <div className="form-group">
            <label>선교헌금</label>
            <input type="number" {...register("price4")} onBlur={handleChangePrice} />
            {errors.price?.type === "required" && <FormError errorMessage={"금액을 입력해주세요."} />}
            {errors.price?.type === "menual" && <FormError errorMessage={errors.price.message} />}
          </div>
          <div className="form-group">
            <label>153구제헌금</label>
            <input type="number" {...register("price5")} onBlur={handleChangePrice} />
          </div>
          <div className="form-group required">
            <label>총계</label>
            <input type="number" {...register("price", { required: true })} readOnly />
          </div>
        </div>
        <div className="form-group">
          <label>감사내용</label>
          <textarea {...register("contents")}></textarea>
        </div>
        <div className="btn-group">
          <button className="btn">{sending ? "전송중..." : "결재하기"}</button>
          {/* <input type="submit" value={sending ? "전송중..." : "결재하기"} className="btn" /> */}
        </div>
      </form>
    </>
  );
};
