import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useAuthState } from "../context/authContext";
import { AdminLayout } from "../layouts/admin";
import { AuthLayout } from "../layouts/auth";
import { ClientLayout } from "../layouts/client";
// import { Dashboard } from "../pages/admin/dashboard";
import { PaymentsList } from "../pages/admin/payments";
// import { Search } from "../pages/admin/search";
import { Login } from "../pages/auth/login";
import { Logout } from "../pages/auth/logout";
import { Fail } from "../pages/client/Fail";
import { Home } from "../pages/client/Home";
import { Success } from "../pages/client/Success";
import PrivateRoute from "./privateRoute";

export const IndexRouter = () => {
  const { isLoggedIn } = useAuthState();

  return (
    <Router>
      <Switch>
        <Route path="/auth/:path?" exact>
          <AuthLayout>
            <Route path="/auth/login" exact render={() => (isLoggedIn ? <Redirect to="/admin" /> : <Login />)} />
            <Route path="/auth/logout" exact component={Logout} />
          </AuthLayout>
        </Route>
        <Route path="/admin/:path?" exact>
          <Switch>
            <AdminLayout>
              <Switch>
                <PrivateRoute path="/admin" exact component={PaymentsList} />
                {/* <PrivateRoute path="/admin/payments" exact component={PaymentsList} />
                <PrivateRoute path="/admin/search" exact component={Search} /> */}
                {/* <PrivateRoute path="/admin" exact component={Dashboard} />
                <PrivateRoute path="/admin/payments" exact component={PaymentsList} />
                <PrivateRoute path="/admin/search" exact component={Search} /> */}
              </Switch>
            </AdminLayout>
          </Switch>
        </Route>
        <Route>
          <ClientLayout>
            <Switch>
              <Route path="/" exact={true} component={Home} />
              <Route path="/success" component={Success} />
              <Route path="/fail" component={Fail} />
            </Switch>
          </ClientLayout>
        </Route>
      </Switch>
    </Router>
  );
};
