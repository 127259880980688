import axios from "../api/";

const AuthService = {
  _token: (token = "") => {
    if (token) {
      localStorage.setItem("token", token);
      return;
    }

    return localStorage.getItem("token");
  },

  isLoggedIn: () => {
    const res = !!AuthService._token();

    return res;
  },

  login: async (userId: string, password: string) => {
    try {
      const response = await axios.post(`login`, {
        userId,
        password,
      });

      const { message: token }: any = response.data;

      if (token) {
        localStorage.setItem("token", token);
      }

      return token ?? response.data;
    } catch (e) {
      return e;
    }
  },

  verifyToken: async (token: string) => {
    try {
      const {
        data: { message },
      }: any = await axios.post(`verify`, {
        token,
      });

      return Boolean(message.ok);
    } catch (e) {
      return e;
    }
  },

  logout: () => {
    return localStorage.removeItem("token");
  },
};

export default AuthService;
