import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
// import moment from "moment";
// import "moment/locale/ko";

import axios from "../../api/";

import Paigination from "../../components/comm/pagination";
import { convertToDate, priceToString } from "../../utils";
import { AuthService } from "../../services";
import { useAuthState } from "../../context/authContext";
import { MiniSearchBox } from "../../components/admin/miniSearchBox";
import { SelectBox } from "../../components/comm/selectBox";
import { Helmet } from "react-helmet-async";

// const DATE_FORMAT = "YYYY.MM.DD HH:MM:ss";

const PaymentsTemplateBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .yearBox {
    align-self: flex-end;
    width: 150px;
  }

  .table-group {
    /* border: 1px solid red; */
    margin-top: 10px;
  }

  .searchBox {
    /* border: 1px solid red; */
  }

  .infoBox {
    margin-top: 30px;
    min-height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: #333;
  }

  .payBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .payBox span {
    /* color: red; */
  }

  .sizeBox {
    margin-left: 20px;
  }
`;

const pageSizeOptions = [
  {
    name: "10",
    value: "10",
  },
  {
    name: "25",
    value: "25",
  },
  {
    name: "50",
    value: "50",
  },
  {
    name: "100",
    value: "100",
  },
];

export const PaymentsList = () => {
  const location = useLocation();
  const history = useHistory();

  const { token } = useAuthState();
  const [year, setYear] = useState("");
  const [name, setName] = useState("");
  const [ssn, setSsn] = useState("");
  const [email, setEmail] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPay, setTotalPay] = useState(0);
  const [currentPagePay, setCurrentPagePay] = useState(0);
  const [posts, setPosts] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(0);
  const [isPage, setIsPage] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const query = queryString.parse(location?.search);
        const { year, page = 1, size, name = "", ssn = "", email = "" }: any = query;

        const today = new Date();
        const targetYear = year ?? (today.getMonth() > 5 ? today.getFullYear() : today.getFullYear() - 1);
        const tagetSize = size ?? (localStorage.getItem("defaultPageSize") ? Number(localStorage.getItem("defaultPageSize")) : 25);

        setYear(`${targetYear}`);
        setCurrentPage(page);
        setName(name ?? "");
        setSsn(ssn ?? "");
        setEmail(email ?? "");
        setPageSize(tagetSize);

        let url = `payments?year=${targetYear}&page=${page}`;

        url += name ? `&name=${name}` : "";
        url += ssn ? `&ssn=${ssn}` : "";
        url += email ? `&email=${email}` : "";

        if (!name && !ssn && !email) {
          setIsPage(true);
          if (tagetSize !== 25) {
            url += `&size=${tagetSize}`;
          }
        } else {
          setIsPage(false);
          url += `&isPage=false`;
        }

        const {
          data: { message: response },
        }: any = await axios.get(url);

        const { pageInfo = {}, paymentInfo = {}, results = [] } = response;

        if (results) {
          setTotal(pageInfo.totalResults);
          setTotalPage(pageInfo.totalPage);
          setTotalPay(paymentInfo.totalPay);
          setCurrentPagePay(paymentInfo.currentPagePay);
          setPosts(results);
        }
      } catch (e) {
        const ok = await AuthService.verifyToken(token);
        if (!ok) history.push(`/auth/logout`);
      }
    })();
  }, [location, history, token]);

  return (
    <PaymentsTemplateBlock>
      <Helmet>
        <title>관리자페이지 | JCC 인터넷 헌금 서비스</title>
      </Helmet>
      <div className="searchBox">
        <MiniSearchBox
          name={name}
          ssn={ssn}
          email={email}
          onSearch={(query) => {
            history.push(`/admin?year=${year}${query}`);
          }}
        />
      </div>

      <div className="infoBox">
        <div className="cntBox">
          {total !== 0 && `전체 : ${total}건`}
          {isPage && `- ${currentPage} page / ${totalPage} pages`}
        </div>
        <div className="payBox">
          {year}년 전체 : {priceToString(totalPay ?? 0)} 원
          {isPage && (
            <>
              {` /  현재 페이지 : ${priceToString(currentPagePay ?? 0)} 원`}
              {pageSize && (
                <div className="sizeBox">
                  <SelectBox
                    options={pageSizeOptions}
                    defaultValue={`${pageSize}`}
                    onSelcet={(changePageSize) => {
                      localStorage.setItem("defaultPageSize", changePageSize);
                      history.push(`/admin?year=${year}&size=${changePageSize}`);
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="table-group full-width">
        <table>
          <thead>
            <tr>
              <th>날짜</th>
              <th>이름</th>
              <th>주민번호</th>
              <th>메일</th>
              <th className="price">십일조</th>
              <th className="price">감사</th>
              <th className="price">건축</th>
              <th className="price">선교</th>
              <th className="price">구제</th>
              <th className="price">합계</th>
            </tr>
          </thead>
          <tbody>
            {posts &&
              posts.map((post: any) => {
                return (
                  <tr key={post.num}>
                    <td>{convertToDate(post.reg_date)}</td>
                    <td>{post.name}</td>
                    <td>
                      {post.jumin1}-{post.jumin2}
                    </td>
                    <td>{post.email}</td>
                    <td className="right">{priceToString(post.price1 ?? 0)}</td>
                    <td className="right">{priceToString(post.price2 ?? 0)}</td>
                    <td className="right">{priceToString(post.price3 ?? 0)}</td>
                    <td className="right">{priceToString(post.price4 ?? 0)}</td>
                    <td className="right">{priceToString(post.price5 ?? 0)}</td>
                    <td className="right result">{priceToString(post.total ?? 0)}</td>
                  </tr>
                );
              })}
            {posts.length === 0 && (
              <tr>
                <td colSpan={10} style={{ lineHeight: 10 }}>
                  no data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div>
        {isPage && total && (
          <Paigination
            page={+currentPage}
            total={total}
            postsPerPage={pageSize}
            paginate={(changePage: number) => {
              history.push(`/admin?year=${year}&page=${changePage}`);
            }}
          />
        )}
      </div>
    </PaymentsTemplateBlock>
  );
};
