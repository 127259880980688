import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthState } from "../context/authContext";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { isLoggedIn } = useAuthState();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...rest} {...props} /> : <Redirect to={{ pathname: "/auth/login", state: { from: props.location } }} />
      }
    />
  );
};

export default PrivateRoute;
