import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Footer } from "../components/layouts/footer";
import { Header } from "../components/layouts/header";

const GlobalStyle = createGlobalStyle`
  body {
    background: #cf000f;
  }
`;

const ContainerBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 540px;
  margin: 0 auto;
  height: 100vh;

  header {
    height: 62px;
    background: #003063;
    position: relative;
  }

  .rightMenu {
    position: absolute;
    right: 20px;
    top: 10px;
  }

  .rightMenu ul li {
    margin-bottom: 10px;
  }

  .rightMenu label {
    margin-left: 10px;
    color: #fff;
    font-weight: 500;
  }

  header h1 {
    margin: 5px 0 0 5px;
  }

  main {
    flex: 1;
  }

  main .titleWrap {
    background: #000;
    text-align: center;
    height: 60px;
  }

  main .titleWrap h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: #cf000f;
    color: #fff;
  }

  main .content {
    background: #fff;
    height: 100%;
  }

  main .content .wrap {
    padding: 20px 50px;
  }

  footer {
    background: #003063;
    padding: 5px 10px 10px;
    text-align: center;
  }

  footer nav ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
  }

  footer nav ul li {
    margin: 0 5px;
  }

  footer .copyright {
    font-size: 12px;
    color: #fff;
  }
`;

export const ClientLayout: React.FC = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <ContainerBlock>
        <Header />
        <main>
          <div className="titleWrap">
            <h3>인터넷 신용카드 헌금 서비스</h3>
          </div>
          <div className="content">
            <div className="wrap">{children}</div>
          </div>
        </main>
        <Footer />
      </ContainerBlock>
    </>
  );
};
