import React from "react";
import logo from "../../images/logo.png";

export const Header = () => {
  const goCardLink = () => {
    // window.location.href = "https://dmc.jcc.tv/";
  };

  const goMobileLink = () => {
    window.location.href = "http://61.42.200.20/_MobilePay/";
  };

  return (
    <header>
      <h1>
        <img src={logo} alt="Jesus Centered Church" />
      </h1>
      <div className="rightMenu">
        <ul>
          <li>
            <input id="rdo1" name="rdo1" type="radio" onClick={goCardLink} defaultChecked={true} />
            <label>신용카드 헌금</label>
          </li>
          <li>
            <input id="rdo2" name="rdo2" type="radio" onClick={goMobileLink} defaultChecked={false} />
            <label>핸드폰 헌금</label>
          </li>
        </ul>
      </div>
    </header>
  );
};
