import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { FormError } from "../../components/form-error";
import { useAuthDispatch } from "../../context/authContext";
import { AuthService } from "../../services";
import { useHistory } from "react-router";

const LoginHeadBlock = styled.div`
  text-align: center;

  padding-top: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e9ecef;

  h4 {
    margin: 0;
    font-size: 36px;
    color: #343a40;
  }
`;

const LoginBodyBlock = styled.div`
  flex: 1;
  margin-top: 25px;
  padding: 20px 32px;
  padding-bottom: 48px;
  overflow-y: auto;

  .form-group {
    margin-bottom: 20px;
  }

  .form-group label {
    margin-bottom: 10px;
  }

  .btn {
    margin-top: 20px;
    width: 100%;
  }
`;

interface ILoginForm {
  userId: string;
  password: string;
}

export const Login = () => {
  const {
    register,
    getValues,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ILoginForm>({
    mode: "onChange",
  });

  const [loading, setLoading] = useState(false);
  const dispath = useAuthDispatch();
  const history = useHistory();

  const onSubmit = async () => {
    if (!loading) {
      setLoading(true);
      const { userId, password } = getValues();

      dispath({ type: "REQUEST_LOGIN" });
      const response = await AuthService.login(userId, password);

      if (typeof response !== "string") {
        dispath({ type: "LOGIN_ERROR", error: response });
        setLoading(false);
        setError("userId", {
          type: "menual",
          message: "아이디와 비밀번호를 정확히 입력해 주세요.",
        });
        return false;
      }

      const payload = {
        token: response,
        user: {
          userId,
        },
      };

      dispath({ type: "LOGIN", payload });
      history.push(`/admin`);
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | JCC 인터넷 헌금 서비스</title>
      </Helmet>
      <LoginHeadBlock>
        <h4>LOGIN</h4>
      </LoginHeadBlock>
      <LoginBodyBlock>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>아이디</label>
            <input type="text" {...register("userId", { required: "아이디를 입력해주세요." })} placeholder="아이디를 입력해주세요." />
            {errors.userId?.type === "required" && <FormError errorMessage={errors.userId.message} />}
          </div>
          <div className="form-group">
            <label>비밀번호</label>
            <input
              type="password"
              {...register("password", { required: "비밀번호를 입력해주세요." })}
              placeholder="비밀번호를 입력해주세요."
            />
            {errors.password?.type === "required" && <FormError errorMessage={errors.password.message} />}
          </div>
          <div className="btn-group">
            <button className="btn">{loading ? "전송중..." : "로그인"}</button>
          </div>
          {errors.userId?.type === "menual" && <FormError errorMessage={errors.userId.message} />}
        </form>
      </LoginBodyBlock>
    </>
  );
};
