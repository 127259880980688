import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import styled, { createGlobalStyle } from "styled-components";
import { SelectBox } from "../components/comm/selectBox";

const GlobalStyle = createGlobalStyle`
  body {
    background: #e9ecef;
  }
`;

const AdminWrapBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  header {
    width: 100%;
    height: 50px;
    background: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    position: fixed;
    z-index: 100;

    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12) !important;
  }

  header h1 {
    font-size: 18px;
    color: #333;
    margin: 15px 0 0 20px;
  }

  header nav {
    position: absolute;
    right: 20px;
    top: 8px;
  }

  header nav ul {
    display: flex;
  }

  header nav ul li {
    margin: auto 20px;
    padding: auto 20px;
  }

  header nav ul li:last-child {
    margin-right: 0;
  }

  main {
    width: 1140px;

    margin-top: 70px;
    padding: 30px 30px 50px;

    min-height: 300px;

    position: relative; /* 추후 박스 하단에 추가 버튼을 위치시키기 위한 설정 */
    background: white;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  }

  .yearBox {
    width: 150px;
  }
`;

type yearOptionType = {
  value: string;
  name: string;
};

const today = new Date();
const thisYear = today.getFullYear();
// const targetYear = year ?? (today.getMonth() > 5 ? today.getFullYear() : today.getFullYear() - 1);

const yearOptions: yearOptionType[] = [];

for (let i = thisYear; i > 2000; i--) {
  yearOptions.push({
    value: i + "",
    name: i + "년",
  });
}

export const AdminLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const [year, setYear] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const query = queryString.parse(location?.search);
        const { year }: any = query;

        const today = new Date();
        const targetYear = year ?? (today.getMonth() > 5 ? today.getFullYear() : today.getFullYear() - 1);

        setYear(`${targetYear}`);
      } catch (e) {}
    })();
  }, [location]);

  return (
    <>
      <GlobalStyle />
      <AdminWrapBlock>
        <header>
          <h1>JCC 인터넷 헌금 서비스</h1>
          <nav>
            <ul>
              <li>
                <div className="yearBox">
                  {year && (
                    <SelectBox
                      options={yearOptions}
                      defaultValue={year}
                      onSelcet={(changeYear) => {
                        history.push(`/admin?year=${changeYear}`);
                      }}
                    />
                  )}
                </div>
              </li>
              <li>
                <Link to="/auth/logout">로그아웃</Link>
              </li>
            </ul>
          </nav>
        </header>
        <main>{children}</main>
      </AdminWrapBlock>
    </>
  );
};
