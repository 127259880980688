import React from "react";

export const Footer = () => {
  return (
    <footer>
      <nav>
        <ul>
          <li>
            <a href="/">[이용전 잠깐!!!]</a>
          </li>
          <li>
            <a href="/">[도움말]</a>
          </li>
          <li>
            <a href="/">[해외성도]</a>
          </li>
          <li>
            <a href="/">[English]</a>
          </li>
        </ul>
      </nav>
      <p className="copyright">Copyright ⓒ 2000 Jesus Centered Church All rights reserved.</p>
    </footer>
  );
};
