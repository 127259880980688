import React, { createContext, Dispatch, useContext, useReducer } from "react";
import { AuthService } from "../services";

type State = {
  loading: boolean;
  user: {
    userId: number;
  } | null;
  token: string;
  isLoggedIn: boolean;
  errorMessage: any;
};

export type loginPaylod = {
  user: any;
  token: string;
};

type Action =
  | { type: "REQUEST_LOGIN" }
  | { type: "LOGIN"; payload: loginPaylod }
  | { type: "LOGOUT" }
  | { type: "LOGIN_ERROR"; error: any };

export type AuthDispatch = Dispatch<Action>;

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...state,
        loading: true,
      };
    case "LOGIN":
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isLoggedIn: true,
        loading: false,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
        token: "",
        isLoggedIn: false,
      };
    case "LOGIN_ERROR":
      return {
        ...state,
        loading: false,
        errorMessage: action.error,
      };

    default:
      throw new Error("Unhandled action");
  }
};

const AuthStateContext = createContext<State | null>(null);
const AuthDispatchContext = createContext<AuthDispatch | null>(null);

const initialState: State = {
  loading: false,
  token: AuthService._token() ?? "",
  user: null,
  isLoggedIn: AuthService.isLoggedIn(),
  errorMessage: null,
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispath] = useReducer(reducer, initialState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispath}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => {
  const state = useContext(AuthStateContext);
  if (!state) throw new Error("Cannot find AuthProvider"); // 유효하지 않을땐 에러를 발생
  return state;
};

export const useAuthDispatch = () => {
  const dispatch = useContext(AuthDispatchContext);
  if (!dispatch) throw new Error("Cannot find AuthProvider"); // 유효하지 않을땐 에러를 발생
  return dispatch;
};
