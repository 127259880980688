import React, { useEffect, useState } from "react";
import axios from "axios";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

const secretKey = `${process.env.REACT_APP_TOSS_SECRIT_KEY}`;
const API_ENDPONIT = `${process.env.REACT_APP_API_ENDPOINT}`;

export const Success = ({ location }: any) => {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");

  const history = useHistory();

  const chkOk = () => {
    // eslint-disable-next-line
    self.close();
  };

  useEffect(() => {
    (async () => {
      try {
        const query = queryString.parse(location?.search);
        const { paymentKey, orderId, amount } = query;
        console.log(paymentKey, orderId, amount);
        const response = await axios.post(
          `https://api.tosspayments.com/v1/payments/${paymentKey}`,
          {
            orderId,
            amount,
          },
          {
            headers: {
              Authorization: "Basic " + Buffer.from(secretKey + ":").toString("base64"),
              "Content-Type": "application/json",
            },
          }
        );

        if (response) {
          const res = await axios.patch(`${API_ENDPONIT}payment/${orderId}`, {
            result: true,
          });

          if (res) {
            console.log(response);
            const { data }: any = response;
            setName(data.orderName);
            setPrice(data.totalAmount);
            setLoading(false);
          }
        }
      } catch (error: any) {
        console.log(error);
        // history.push(`/fail?code=${error.response?.body?.code}&message=${error.response?.body?.message}`);
      }
    })();
  }, [location, history]);

  return (
    <>
      {loading ? (
        <div>로딩중...</div>
      ) : (
        <>
          <h3>결재 완료</h3>
          <div>
            <br />
            <p> 총 결재금액 : {price}원</p>
            <br />
            <br />
            <p>
              {name} 성도님
              <br />
              헌금이 정상적으로 처리되었습니다.
              <br />
              이 소중한 헌금이
              <br />
              지구촌을 복음화 하는데
              <br />
              귀중히 쓰임받을 것입니다.
              <br />
              반드시 그날에 하나님께 기억되고
              <br />
              당신이 심은것에 100배를 보상하십니다.
              <br />
              <br />
              확인버튼을 누르시면 창이 닫힙니다. <br />
              <br />
              <button className="btn" onClick={chkOk}>
                확인
              </button>
            </p>
          </div>
        </>
      )}
    </>
  );
};
